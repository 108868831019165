<template>
    <a-spin :spinning="loading">
    <div class="home-container" >
        <div class="header">
            <img class="logo" src="@/assets/login/logo.png">
            <div class="time-view">
                <div class="date">
                    <span>{{this.weekValue}}</span>
                    <span>{{this.dateValue}}</span>
                </div>
                <div class="time">
                    <div style="height:30px"></div>
                    <div>
                        <span>{{this.upOrDown}}</span>
                        <span style="font-size: 40px;margin-left:10px">{{this.timeValue}}</span>
                    </div>
                    
                </div>
                <div class="userlogin">
                     <a-popover placement="bottom">
                        <div slot="content" @click="logoutAction"> <img src="@/assets/home/outlogin.png" class="imgout"><span class="outlogin">退出登录</span></div>
                         <img :src="head_portrait" class="img" v-if="head_portrait">
                         <div class="headernoimg" v-else :style="randomRgb()">{{namestring}}</div>
                        <span>{{username}}</span>
                         <a-icon type="down" class="icon"/>
                      </a-popover>
                    
                </div>
            </div>
        </div>
        <div class="content">
            <div class="home-tip">
                欢迎使用
                <br/>
                一体化智能商品<span>分拣系统</span>
            </div>
            <div class="menu-view">
                <img src="@/assets/home/menu_1.png" @click="menuClick('/sorting')">
                <!-- <img src="@/assets/home/menu_2.png" @click="menuClick('/errorsorting')"> -->
                 <img src="@/assets/home/menu_2.png" @click="menuClick('/single_sorting')">
                <img src="@/assets/home/menu_3.png" @click="menuClick('/customer_sorting')">
                <img src="@/assets/home/menu_4.png" @click="menuClick('/printer')">
            </div>
        </div>
    </div>
    </a-spin>
</template>

<script>
  import {mapMutations} from "vuex";

  export default {
    name: "home",
    data() {
      return {
        weekValue: null,
        dateValue: null,
        upOrDown: null,
        timeValue: null,
        loading: false,
        username:'',
        head_portrait:'',
        namestring:'蓝'
      }
    },
    created() {
        const user = localStorage.getItem('memberstore')
        console.log(JSON.parse(user))
        if(user){
            this.username = JSON.parse(user).username
            if(this.username){
                this.namestring=this.username.substring(0,1);
            }
            this.head_portrait= JSON.parse(user).head_portrait
        }

      this.getDateTime()
      setInterval(() => {
        this.getDateTime()
      }, 1000)
    },
    methods: {
      randomRgb(){
        let R=Math.floor(Math.random()*130+110);
        let G=Math.floor(Math.random()*130+110);
        let B=Math.floor(Math.random()*130+110);
        return {
            background:'rgb('+R+','+G+','+B+')'
        }
      },
      ...mapMutations(['logout']),
      getDateTime() {
        let date = new Date();
        var weeks = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
        var week = weeks[date.getDay()];
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let d = date.getDate()
        let hour = date.getHours()
        let minute = date.getMinutes();

        let updown = '';
        if (hour < 12) {
          updown = '上午'
        } else {
          updown = '下午'
        }
        this.weekValue = week
        this.dateValue = `${year}年${month}月${d}日`
        this.upOrDown = updown
        this.timeValue = `${hour}:${minute}`
      },
      menuClick(router) {
        this.$router.push(router)
      },
      logoutAction() {
        this.loading = true
        this.rq.post('/merapi/sorting/o-auth/logout').then(res => {
          if (res.code === 200) {
            this.logout()
            this.$router.push('/login')

          } else {
            this.$message.error(res.message)
          }
          this.loading = false
        }).catch(e => {
          this.loading = false
        })
      }
    }

  }
</script>

<style scoped lang="less">
    .outlogin{
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 28px;
      letter-spacing: 1px;   
      margin-left:17px;
      display:inline-block;
    }
    .imgout{
        width:20px;
        position:relative;
        top:-4px;
    }
    .home-container {
        height: 800px;
        width: 100%;
        background: #F1F2F2;
        background-image: url("../assets/home/home_bg.png");
        background-size: 100% 376px;
        background-repeat: no-repeat;
        background-position: bottom;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 150px;
            width: 100%;
            padding: 0 50px;
            &>img {
                width: 16.41%;
                height: auto;
            }
            .time-view {
                display: flex;
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #2C2543;
                line-height: 38px;
                .date{
                    span{
                        display:block;
                    }
                }
                .time{
                    margin-left:17px;
                }
                
                .userlogin{
                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    letter-spacing: 1px;
                    color: #000000;
                    margin-left:39px;
                     position:relative;
                     top:30px;
                     cursor: pointer;
                    .img{
                        width: 40px;
                        height: 40px;
                        border-radius:100%;
                        background: #fff;
                         margin-right:22px;
                        
                    }
                    .headernoimg{
                            width: 40px;
                            height: 40px;
                            border-radius: 100%;
                            background: #eee;
                            margin-right: 22px;
                            display: inline-block;
                            color:#fff;
                            text-align: center;
                    }
                   
                   .icon{
                       color:#B2B2B2;
                       transform:scale(1.2,1);
                       margin-left:8px;
                   } 
                }
                
                &>:nth-child(1) {
                    margin-right: 15px;
                }
               
                &>:nth-child(4) {
                    font-size: 40px;
                }
            }
        }
        .content {
            flex: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-top: 50px;
            .home-tip {
                text-align: center;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #000000;
                line-height: 56px;
                letter-spacing: 2px;
                &>span {
                    color: #0427AF;
                }
            }
            .menu-view {

                display: flex;
                justify-content: center;
                &>img {
                    width: 300px;
                    height: 369px;

                }
                &>img+img {
                    margin-left: -20px;
                }
                &>img:active {
                    opacity: 0.5;
                }
            }
        }
    }

</style>